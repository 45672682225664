import React from 'react';
import ComponentCreator from '@docusaurus/ComponentCreator';

export default [
  {
    path: '/markdown-page',
    component: ComponentCreator('/markdown-page', 'e33'),
    exact: true
  },
  {
    path: '/docs',
    component: ComponentCreator('/docs', 'ff8'),
    routes: [
      {
        path: '/docs/client-sdk',
        component: ComponentCreator('/docs/client-sdk', 'a3a'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/client-sdk/advanced',
        component: ComponentCreator('/docs/client-sdk/advanced', '019'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/client-sdk/authentication',
        component: ComponentCreator('/docs/client-sdk/authentication', '1d1'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/client-sdk/configuration',
        component: ComponentCreator('/docs/client-sdk/configuration', '582'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/client-sdk/data',
        component: ComponentCreator('/docs/client-sdk/data', '4e4'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/client-sdk/events',
        component: ComponentCreator('/docs/client-sdk/events', 'f99'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/client-sdk/getting-started',
        component: ComponentCreator('/docs/client-sdk/getting-started', 'b79'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/client-sdk/how-it-works',
        component: ComponentCreator('/docs/client-sdk/how-it-works', 'e62'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/client-sdk/messaging',
        component: ComponentCreator('/docs/client-sdk/messaging', '45c'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/client-sdk/permissions',
        component: ComponentCreator('/docs/client-sdk/permissions', '9b4'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/client-sdk/profiles',
        component: ComponentCreator('/docs/client-sdk/profiles', '98c'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/client-sdk/queries',
        component: ComponentCreator('/docs/client-sdk/queries', 'a0b'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/client-sdk/react-native',
        component: ComponentCreator('/docs/client-sdk/react-native', 'c28'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/concepts',
        component: ComponentCreator('/docs/concepts', 'f9e'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/concepts/accounts-and-identity',
        component: ComponentCreator('/docs/concepts/accounts-and-identity', '14e'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/concepts/application-contexts',
        component: ComponentCreator('/docs/concepts/application-contexts', 'b64'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/concepts/data-sharing',
        component: ComponentCreator('/docs/concepts/data-sharing', '5bb'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/concepts/data-storage',
        component: ComponentCreator('/docs/concepts/data-storage', 'f22'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/concepts/design-principles',
        component: ComponentCreator('/docs/concepts/design-principles', 'de4'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/concepts/messaging',
        component: ComponentCreator('/docs/concepts/messaging', '4b8'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/concepts/network-architecture',
        component: ComponentCreator('/docs/concepts/network-architecture', '5e9'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/concepts/schemas',
        component: ComponentCreator('/docs/concepts/schemas', 'ee0'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/extensions/blockchain-support',
        component: ComponentCreator('/docs/extensions/blockchain-support', '7b8'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/extensions/credentials/cheqd-credential-service',
        component: ComponentCreator('/docs/extensions/credentials/cheqd-credential-service', 'ab5'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/extensions/credentials/polygon-id',
        component: ComponentCreator('/docs/extensions/credentials/polygon-id', '1bd'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/extensions/credentials/reclaim',
        component: ComponentCreator('/docs/extensions/credentials/reclaim', '7d1'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/extensions/credentials/verifiable-credentials',
        component: ComponentCreator('/docs/extensions/credentials/verifiable-credentials', '1b3'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/extensions/credentials/zkpass',
        component: ComponentCreator('/docs/extensions/credentials/zkpass', '781'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/extensions/personal-data-bridge',
        component: ComponentCreator('/docs/extensions/personal-data-bridge', '286'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/extensions/verida-uris',
        component: ComponentCreator('/docs/extensions/verida-uris', 'c32'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/extensions/vue-starter-kit',
        component: ComponentCreator('/docs/extensions/vue-starter-kit', '3fb'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/extras/capabilities',
        component: ComponentCreator('/docs/extras/capabilities', 'ef7'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/extras/demos',
        component: ComponentCreator('/docs/extras/demos', '189'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/extras/migrate-mainnet',
        component: ComponentCreator('/docs/extras/migrate-mainnet', '0dc'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/extras/where-to-store-web3-data',
        component: ComponentCreator('/docs/extras/where-to-store-web3-data', '881'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/glossary',
        component: ComponentCreator('/docs/glossary', 'be2'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/infrastructure/networks',
        component: ComponentCreator('/docs/infrastructure/networks', '58a'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/infrastructure/storage-node',
        component: ComponentCreator('/docs/infrastructure/storage-node', '076'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/intro',
        component: ComponentCreator('/docs/intro', 'aed'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/single-sign-on-sdk',
        component: ComponentCreator('/docs/single-sign-on-sdk', 'b1a'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/single-sign-on-sdk/getting-started',
        component: ComponentCreator('/docs/single-sign-on-sdk/getting-started', '7dc'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/single-sign-on-sdk/sso-authentication-server',
        component: ComponentCreator('/docs/single-sign-on-sdk/sso-authentication-server', '5c6'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/single-sign-on-sdk/wallet-connect',
        component: ComponentCreator('/docs/single-sign-on-sdk/wallet-connect', 'a93'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/single-sign-on-sdk/web-user',
        component: ComponentCreator('/docs/single-sign-on-sdk/web-user', '3d2'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/tutorial/databases',
        component: ComponentCreator('/docs/tutorial/databases', 'ffb'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/tutorial/datastores',
        component: ComponentCreator('/docs/tutorial/datastores', 'd97'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/tutorial/encryption',
        component: ComponentCreator('/docs/tutorial/encryption', 'c34'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/tutorial/events',
        component: ComponentCreator('/docs/tutorial/events', '15e'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/tutorial/introduction',
        component: ComponentCreator('/docs/tutorial/introduction', '16a'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/tutorial/messaging',
        component: ComponentCreator('/docs/tutorial/messaging', '8eb'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/tutorial/public_profile',
        component: ComponentCreator('/docs/tutorial/public_profile', '506'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/tutorial/request_data',
        component: ComponentCreator('/docs/tutorial/request_data', 'd52'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/tutorial/social_media_data',
        component: ComponentCreator('/docs/tutorial/social_media_data', '281'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/tutorial/SSO',
        component: ComponentCreator('/docs/tutorial/SSO', '0a4'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/tutorial/verifiable_credentials',
        component: ComponentCreator('/docs/tutorial/verifiable_credentials', '654'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/tutorial/walletconnect_verida',
        component: ComponentCreator('/docs/tutorial/walletconnect_verida', '6d7'),
        exact: true,
        sidebar: "tutorialSidebar"
      }
    ]
  },
  {
    path: '/',
    component: ComponentCreator('/', 'df8'),
    exact: true
  },
  {
    path: '*',
    component: ComponentCreator('*'),
  },
];
