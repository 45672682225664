/*
 * AUTOGENERATED - DON'T EDIT
 * Your edits in this file will be overwritten in the next build!
 * Modify the docusaurus.config.js file at your site's root instead.
 */
export default {
  "title": "Verida Developers",
  "tagline": "Welcome to your Web3 toolkit!",
  "url": "https://developers.verida.io",
  "baseUrl": "/",
  "onBrokenLinks": "throw",
  "onBrokenMarkdownLinks": "warn",
  "favicon": "img/new_favicon.ico",
  "organizationName": "verida",
  "projectName": "verida",
  "trailingSlash": false,
  "themes": [
    "@docusaurus/theme-live-codeblock"
  ],
  "presets": [
    [
      "@docusaurus/preset-classic",
      {
        "docs": {
          "sidebarPath": "/codebuild/output/src3375852349/src/documentation/sidebars.js",
          "remarkPlugins": [
            {
              "sync": true
            }
          ]
        },
        "sitemap": {
          "changefreq": "weekly",
          "priority": 0.5
        },
        "pages": {
          "remarkPlugins": [
            null
          ]
        },
        "blog": false,
        "theme": {
          "customCss": "/codebuild/output/src3375852349/src/documentation/src/css/custom.css"
        }
      }
    ]
  ],
  "scripts": [
    {
      "src": "https://plausible.io/js/plausible.js",
      "defer": true,
      "data-domain": "developers.verida.network"
    }
  ],
  "themeConfig": {
    "colorMode": {
      "defaultMode": "light",
      "disableSwitch": true,
      "respectPrefersColorScheme": false
    },
    "metadata": [
      {
        "name": "keywords",
        "content": "verida, personal data"
      },
      {
        "name": "robots",
        "content": "max-image-preview:large"
      }
    ],
    "navbar": {
      "logo": {
        "alt": "Verida Logo",
        "src": "img/new_logo.svg"
      },
      "items": [
        {
          "type": "doc",
          "docId": "intro",
          "position": "left",
          "label": "Documentation"
        },
        {
          "href": "https://verida.io",
          "label": "Verida Homepage",
          "position": "left"
        },
        {
          "href": "https://github.com/verida",
          "label": "GitHub",
          "position": "right"
        }
      ],
      "hideOnScroll": false
    },
    "image": "img/verida_social_preview2.png",
    "footer": {
      "style": "light",
      "links": [
        {
          "title": "Product",
          "items": [
            {
              "label": "Solutions",
              "href": "https://www.verida.io/solutions"
            },
            {
              "label": "Get App",
              "href": "https://www.verida.io"
            }
          ]
        },
        {
          "title": "Company",
          "items": [
            {
              "label": "About",
              "href": "https://www.verida.io/about-us"
            },
            {
              "label": "Careers",
              "href": "https://www.verida.io/careers"
            },
            {
              "label": "Blog",
              "href": "https://news.verida.io"
            }
          ]
        },
        {
          "title": "Community",
          "items": [
            {
              "label": "Telegram",
              "href": "https://t.me/verida_community"
            },
            {
              "label": "Twitter",
              "href": "https://twitter.com/verida_io"
            },
            {
              "label": "Discord",
              "href": "https://discord.com/invite/qb6vS43"
            },
            {
              "label": "Medium",
              "href": "https://medium.com/verida"
            },
            {
              "label": "LinkedIn",
              "href": "https://www.linkedin.com/company/verida-technology/"
            }
          ]
        },
        {
          "title": "Developer",
          "items": [
            {
              "label": "Develop with verida",
              "href": "https://www.verida.io/verida-developers"
            },
            {
              "label": "Whitepaper",
              "href": "https://www.verida.io/whitepaper?utm=devfooter"
            },
            {
              "label": "Documentation",
              "href": "https://developers.verida.io/"
            },
            {
              "label": "Github",
              "href": "https://github.com/verida/"
            }
          ]
        }
      ],
      "copyright": "Copyright © 2024 Verida. Built with Docusaurus."
    },
    "prism": {
      "theme": {
        "plain": {
          "color": "#393A34",
          "backgroundColor": "#f6f8fa"
        },
        "styles": [
          {
            "types": [
              "comment",
              "prolog",
              "doctype",
              "cdata"
            ],
            "style": {
              "color": "#999988",
              "fontStyle": "italic"
            }
          },
          {
            "types": [
              "namespace"
            ],
            "style": {
              "opacity": 0.7
            }
          },
          {
            "types": [
              "string",
              "attr-value"
            ],
            "style": {
              "color": "#e3116c"
            }
          },
          {
            "types": [
              "punctuation",
              "operator"
            ],
            "style": {
              "color": "#393A34"
            }
          },
          {
            "types": [
              "entity",
              "url",
              "symbol",
              "number",
              "boolean",
              "variable",
              "constant",
              "property",
              "regex",
              "inserted"
            ],
            "style": {
              "color": "#36acaa"
            }
          },
          {
            "types": [
              "atrule",
              "keyword",
              "attr-name",
              "selector"
            ],
            "style": {
              "color": "#00a4db"
            }
          },
          {
            "types": [
              "function",
              "deleted",
              "tag"
            ],
            "style": {
              "color": "#d73a49"
            }
          },
          {
            "types": [
              "function-variable"
            ],
            "style": {
              "color": "#6f42c1"
            }
          },
          {
            "types": [
              "tag",
              "selector",
              "keyword"
            ],
            "style": {
              "color": "#00009f"
            }
          }
        ]
      },
      "darkTheme": {
        "plain": {
          "color": "#F8F8F2",
          "backgroundColor": "#282A36"
        },
        "styles": [
          {
            "types": [
              "prolog",
              "constant",
              "builtin"
            ],
            "style": {
              "color": "rgb(189, 147, 249)"
            }
          },
          {
            "types": [
              "inserted",
              "function"
            ],
            "style": {
              "color": "rgb(80, 250, 123)"
            }
          },
          {
            "types": [
              "deleted"
            ],
            "style": {
              "color": "rgb(255, 85, 85)"
            }
          },
          {
            "types": [
              "changed"
            ],
            "style": {
              "color": "rgb(255, 184, 108)"
            }
          },
          {
            "types": [
              "punctuation",
              "symbol"
            ],
            "style": {
              "color": "rgb(248, 248, 242)"
            }
          },
          {
            "types": [
              "string",
              "char",
              "tag",
              "selector"
            ],
            "style": {
              "color": "rgb(255, 121, 198)"
            }
          },
          {
            "types": [
              "keyword",
              "variable"
            ],
            "style": {
              "color": "rgb(189, 147, 249)",
              "fontStyle": "italic"
            }
          },
          {
            "types": [
              "comment"
            ],
            "style": {
              "color": "rgb(98, 114, 164)"
            }
          },
          {
            "types": [
              "attr-name"
            ],
            "style": {
              "color": "rgb(241, 250, 140)"
            }
          }
        ]
      },
      "additionalLanguages": [],
      "magicComments": [
        {
          "className": "theme-code-block-highlighted-line",
          "line": "highlight-next-line",
          "block": {
            "start": "highlight-start",
            "end": "highlight-end"
          }
        }
      ]
    },
    "docs": {
      "versionPersistence": "localStorage",
      "sidebar": {
        "hideable": false,
        "autoCollapseCategories": false
      }
    },
    "tableOfContents": {
      "minHeadingLevel": 2,
      "maxHeadingLevel": 3
    },
    "liveCodeBlock": {
      "playgroundPosition": "bottom"
    }
  },
  "plugins": [
    [
      "/codebuild/output/src3375852349/src/documentation/node_modules/@cmfcmf/docusaurus-search-local/lib/server/index.js",
      {
        "indexBlog": false,
        "indexPages": false
      }
    ],
    "./webpack_plugin"
  ],
  "baseUrlIssueBanner": true,
  "i18n": {
    "defaultLocale": "en",
    "path": "i18n",
    "locales": [
      "en"
    ],
    "localeConfigs": {}
  },
  "onDuplicateRoutes": "warn",
  "staticDirectories": [
    "static"
  ],
  "customFields": {},
  "headTags": [],
  "stylesheets": [],
  "clientModules": [],
  "titleDelimiter": "|",
  "noIndex": false,
  "markdown": {
    "mermaid": false
  }
};
