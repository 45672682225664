export default {
  '00fd1610': [() => import(/* webpackChunkName: '00fd1610' */ '@site/docs/tutorial/request_data.mdx'), '@site/docs/tutorial/request_data.mdx', require.resolveWeak('@site/docs/tutorial/request_data.mdx')],
  '0634e4cd': [() => import(/* webpackChunkName: '0634e4cd' */ '@site/docs/client-sdk/events.md'), '@site/docs/client-sdk/events.md', require.resolveWeak('@site/docs/client-sdk/events.md')],
  '08ed06e6': [() => import(/* webpackChunkName: '08ed06e6' */ '@site/docs/tutorial/verifiable_credentials.mdx'), '@site/docs/tutorial/verifiable_credentials.mdx', require.resolveWeak('@site/docs/tutorial/verifiable_credentials.mdx')],
  '0e03f5ee': [() => import(/* webpackChunkName: '0e03f5ee' */ '@site/docs/extras/demos.md'), '@site/docs/extras/demos.md', require.resolveWeak('@site/docs/extras/demos.md')],
  '0e384e19': [() => import(/* webpackChunkName: '0e384e19' */ '@site/docs/intro.md'), '@site/docs/intro.md', require.resolveWeak('@site/docs/intro.md')],
  '151a77d7': [() => import(/* webpackChunkName: '151a77d7' */ '@site/docs/tutorial/events.mdx'), '@site/docs/tutorial/events.mdx', require.resolveWeak('@site/docs/tutorial/events.mdx')],
  '17896441': [() => import(/* webpackChunkName: '17896441' */ '@theme/DocItem'), '@theme/DocItem', require.resolveWeak('@theme/DocItem')],
  '1be78505': [() => import(/* webpackChunkName: '1be78505' */ '@theme/DocPage'), '@theme/DocPage', require.resolveWeak('@theme/DocPage')],
  '1f391b9e': [() => import(/* webpackChunkName: '1f391b9e' */ '@theme/MDXPage'), '@theme/MDXPage', require.resolveWeak('@theme/MDXPage')],
  '2ac05ad2': [() => import(/* webpackChunkName: '2ac05ad2' */ '@site/docs/single-sign-on-sdk/single-sign-on-sdk.md'), '@site/docs/single-sign-on-sdk/single-sign-on-sdk.md', require.resolveWeak('@site/docs/single-sign-on-sdk/single-sign-on-sdk.md')],
  '2b3797a7': [() => import(/* webpackChunkName: '2b3797a7' */ '@site/docs/client-sdk/getting-started.md'), '@site/docs/client-sdk/getting-started.md', require.resolveWeak('@site/docs/client-sdk/getting-started.md')],
  '2d989628': [() => import(/* webpackChunkName: '2d989628' */ '@site/docs/single-sign-on-sdk/wallet-connect.md'), '@site/docs/single-sign-on-sdk/wallet-connect.md', require.resolveWeak('@site/docs/single-sign-on-sdk/wallet-connect.md')],
  '3004e2df': [() => import(/* webpackChunkName: '3004e2df' */ '@site/docs/concepts/network-architecture.md'), '@site/docs/concepts/network-architecture.md', require.resolveWeak('@site/docs/concepts/network-architecture.md')],
  '38585540': [() => import(/* webpackChunkName: '38585540' */ '@site/docs/client-sdk/messaging.md'), '@site/docs/client-sdk/messaging.md', require.resolveWeak('@site/docs/client-sdk/messaging.md')],
  '393be207': [() => import(/* webpackChunkName: '393be207' */ '@site/src/pages/markdown-page.md'), '@site/src/pages/markdown-page.md', require.resolveWeak('@site/src/pages/markdown-page.md')],
  '3e5ea7cc': [() => import(/* webpackChunkName: '3e5ea7cc' */ '@site/docs/client-sdk/how-it-works.md'), '@site/docs/client-sdk/how-it-works.md', require.resolveWeak('@site/docs/client-sdk/how-it-works.md')],
  '3e635138': [() => import(/* webpackChunkName: '3e635138' */ '@site/docs/tutorial/encryption.mdx'), '@site/docs/tutorial/encryption.mdx', require.resolveWeak('@site/docs/tutorial/encryption.mdx')],
  '434433e9': [() => import(/* webpackChunkName: '434433e9' */ '@site/docs/extras/where-to-store-web3-data.md'), '@site/docs/extras/where-to-store-web3-data.md', require.resolveWeak('@site/docs/extras/where-to-store-web3-data.md')],
  '459ccf25': [() => import(/* webpackChunkName: '459ccf25' */ '@site/docs/extras/migrate-mainnet.md'), '@site/docs/extras/migrate-mainnet.md', require.resolveWeak('@site/docs/extras/migrate-mainnet.md')],
  '46eb5096': [() => import(/* webpackChunkName: '46eb5096' */ '@site/docs/concepts/data-sharing.md'), '@site/docs/concepts/data-sharing.md', require.resolveWeak('@site/docs/concepts/data-sharing.md')],
  '472c8f07': [() => import(/* webpackChunkName: '472c8f07' */ '@site/docs/tutorial/datastores.mdx'), '@site/docs/tutorial/datastores.mdx', require.resolveWeak('@site/docs/tutorial/datastores.mdx')],
  '49497bc6': [() => import(/* webpackChunkName: '49497bc6' */ '@site/docs/extensions/credentials/reclaim.md'), '@site/docs/extensions/credentials/reclaim.md', require.resolveWeak('@site/docs/extensions/credentials/reclaim.md')],
  '4acaa9c4': [() => import(/* webpackChunkName: '4acaa9c4' */ '@site/docs/concepts/schemas.md'), '@site/docs/concepts/schemas.md', require.resolveWeak('@site/docs/concepts/schemas.md')],
  '4f0f384d': [() => import(/* webpackChunkName: '4f0f384d' */ '@site/docs/tutorial/public_profile.mdx'), '@site/docs/tutorial/public_profile.mdx', require.resolveWeak('@site/docs/tutorial/public_profile.mdx')],
  '520ba175': [() => import(/* webpackChunkName: '520ba175' */ '@site/docs/single-sign-on-sdk/web-user.md'), '@site/docs/single-sign-on-sdk/web-user.md', require.resolveWeak('@site/docs/single-sign-on-sdk/web-user.md')],
  '588d6d79': [() => import(/* webpackChunkName: '588d6d79' */ '@site/docs/client-sdk/data.md'), '@site/docs/client-sdk/data.md', require.resolveWeak('@site/docs/client-sdk/data.md')],
  '5b3c0117': [() => import(/* webpackChunkName: '5b3c0117' */ '@site/docs/extensions/blockchain-support.md'), '@site/docs/extensions/blockchain-support.md', require.resolveWeak('@site/docs/extensions/blockchain-support.md')],
  '5c95e422': [() => import(/* webpackChunkName: '5c95e422' */ '@site/docs/concepts/application-contexts.md'), '@site/docs/concepts/application-contexts.md', require.resolveWeak('@site/docs/concepts/application-contexts.md')],
  '5e9f5e1a': [() => import(/* webpackChunkName: '5e9f5e1a' */ '@generated/docusaurus.config'), '@generated/docusaurus.config', require.resolveWeak('@generated/docusaurus.config')],
  '66f15090': [() => import(/* webpackChunkName: '66f15090' */ '@site/docs/extensions/credentials/cheqd-credential-service.md'), '@site/docs/extensions/credentials/cheqd-credential-service.md', require.resolveWeak('@site/docs/extensions/credentials/cheqd-credential-service.md')],
  '6abf7002': [() => import(/* webpackChunkName: '6abf7002' */ '@site/docs/tutorial/databases.mdx'), '@site/docs/tutorial/databases.mdx', require.resolveWeak('@site/docs/tutorial/databases.mdx')],
  '72337ebf': [() => import(/* webpackChunkName: '72337ebf' */ '@site/docs/client-sdk/profiles.md'), '@site/docs/client-sdk/profiles.md', require.resolveWeak('@site/docs/client-sdk/profiles.md')],
  '741ac5e9': [() => import(/* webpackChunkName: '741ac5e9' */ '@site/docs/client-sdk/advanced.md'), '@site/docs/client-sdk/advanced.md', require.resolveWeak('@site/docs/client-sdk/advanced.md')],
  '76b57476': [() => import(/* webpackChunkName: '76b57476' */ '@site/docs/tutorial/introduction.mdx'), '@site/docs/tutorial/introduction.mdx', require.resolveWeak('@site/docs/tutorial/introduction.mdx')],
  '77fe479c': [() => import(/* webpackChunkName: '77fe479c' */ '@site/docs/concepts/data-storage.md'), '@site/docs/concepts/data-storage.md', require.resolveWeak('@site/docs/concepts/data-storage.md')],
  '78fd2c3a': [() => import(/* webpackChunkName: '78fd2c3a' */ '/codebuild/output/src3375852349/src/documentation/.docusaurus/docusaurus-plugin-content-docs/default/plugin-route-context-module-100.json'), '/codebuild/output/src3375852349/src/documentation/.docusaurus/docusaurus-plugin-content-docs/default/plugin-route-context-module-100.json', require.resolveWeak('/codebuild/output/src3375852349/src/documentation/.docusaurus/docusaurus-plugin-content-docs/default/plugin-route-context-module-100.json')],
  '79108034': [() => import(/* webpackChunkName: '79108034' */ '@site/docs/client-sdk/configuration.md'), '@site/docs/client-sdk/configuration.md', require.resolveWeak('@site/docs/client-sdk/configuration.md')],
  '83902105': [() => import(/* webpackChunkName: '83902105' */ '@site/docs/client-sdk/permissions.md'), '@site/docs/client-sdk/permissions.md', require.resolveWeak('@site/docs/client-sdk/permissions.md')],
  '8718fba4': [() => import(/* webpackChunkName: '8718fba4' */ '@site/docs/infrastructure/networks.md'), '@site/docs/infrastructure/networks.md', require.resolveWeak('@site/docs/infrastructure/networks.md')],
  '8912017c': [() => import(/* webpackChunkName: '8912017c' */ '@site/docs/single-sign-on-sdk/getting-started.md'), '@site/docs/single-sign-on-sdk/getting-started.md', require.resolveWeak('@site/docs/single-sign-on-sdk/getting-started.md')],
  '8d5ef2e6': [() => import(/* webpackChunkName: '8d5ef2e6' */ '@site/docs/client-sdk/authentication.md'), '@site/docs/client-sdk/authentication.md', require.resolveWeak('@site/docs/client-sdk/authentication.md')],
  '8e2ab3d7': [() => import(/* webpackChunkName: '8e2ab3d7' */ '@site/docs/extensions/credentials/polygon-id.md'), '@site/docs/extensions/credentials/polygon-id.md', require.resolveWeak('@site/docs/extensions/credentials/polygon-id.md')],
  '935f2afb': [() => import(/* webpackChunkName: '935f2afb' */ '~docs/default/version-current-metadata-prop-751.json'), '~docs/default/version-current-metadata-prop-751.json', require.resolveWeak('~docs/default/version-current-metadata-prop-751.json')],
  '9a94ab66': [() => import(/* webpackChunkName: '9a94ab66' */ '@site/docs/client-sdk/queries.md'), '@site/docs/client-sdk/queries.md', require.resolveWeak('@site/docs/client-sdk/queries.md')],
  '9c649ef2': [() => import(/* webpackChunkName: '9c649ef2' */ '@site/docs/tutorial/messaging.mdx'), '@site/docs/tutorial/messaging.mdx', require.resolveWeak('@site/docs/tutorial/messaging.mdx')],
  'a3239d04': [() => import(/* webpackChunkName: 'a3239d04' */ '@site/docs/tutorial/social_media_data.mdx'), '@site/docs/tutorial/social_media_data.mdx', require.resolveWeak('@site/docs/tutorial/social_media_data.mdx')],
  'b0fba489': [() => import(/* webpackChunkName: 'b0fba489' */ '@site/docs/client-sdk/react-native.md'), '@site/docs/client-sdk/react-native.md', require.resolveWeak('@site/docs/client-sdk/react-native.md')],
  'b2103946': [() => import(/* webpackChunkName: 'b2103946' */ '@site/docs/concepts/messaging.md'), '@site/docs/concepts/messaging.md', require.resolveWeak('@site/docs/concepts/messaging.md')],
  'b243446d': [() => import(/* webpackChunkName: 'b243446d' */ '@site/docs/single-sign-on-sdk/sso-authentication-server.md'), '@site/docs/single-sign-on-sdk/sso-authentication-server.md', require.resolveWeak('@site/docs/single-sign-on-sdk/sso-authentication-server.md')],
  'b37235d0': [() => import(/* webpackChunkName: 'b37235d0' */ '@site/docs/concepts/accounts-and-identity.md'), '@site/docs/concepts/accounts-and-identity.md', require.resolveWeak('@site/docs/concepts/accounts-and-identity.md')],
  'b632dadd': [() => import(/* webpackChunkName: 'b632dadd' */ '@site/docs/extensions/credentials/verifiable-credentials.md'), '@site/docs/extensions/credentials/verifiable-credentials.md', require.resolveWeak('@site/docs/extensions/credentials/verifiable-credentials.md')],
  'b8e30058': [() => import(/* webpackChunkName: 'b8e30058' */ '@site/docs/concepts/concepts.md'), '@site/docs/concepts/concepts.md', require.resolveWeak('@site/docs/concepts/concepts.md')],
  'bcdf0aaf': [() => import(/* webpackChunkName: 'bcdf0aaf' */ '@site/docs/extensions/personal-data-bridge.md'), '@site/docs/extensions/personal-data-bridge.md', require.resolveWeak('@site/docs/extensions/personal-data-bridge.md')],
  'bda326be': [() => import(/* webpackChunkName: 'bda326be' */ '/codebuild/output/src3375852349/src/documentation/.docusaurus/docusaurus-plugin-content-pages/default/plugin-route-context-module-100.json'), '/codebuild/output/src3375852349/src/documentation/.docusaurus/docusaurus-plugin-content-pages/default/plugin-route-context-module-100.json', require.resolveWeak('/codebuild/output/src3375852349/src/documentation/.docusaurus/docusaurus-plugin-content-pages/default/plugin-route-context-module-100.json')],
  'c4f5d8e4': [() => import(/* webpackChunkName: 'c4f5d8e4' */ '@site/src/pages/index.js'), '@site/src/pages/index.js', require.resolveWeak('@site/src/pages/index.js')],
  'c5e1e266': [() => import(/* webpackChunkName: 'c5e1e266' */ '@site/docs/extensions/vue-starter-kit.md'), '@site/docs/extensions/vue-starter-kit.md', require.resolveWeak('@site/docs/extensions/vue-starter-kit.md')],
  'd6218814': [() => import(/* webpackChunkName: 'd6218814' */ '@site/docs/extras/capabilities.md'), '@site/docs/extras/capabilities.md', require.resolveWeak('@site/docs/extras/capabilities.md')],
  'dc141845': [() => import(/* webpackChunkName: 'dc141845' */ '@site/docs/extensions/credentials/zkpass.md'), '@site/docs/extensions/credentials/zkpass.md', require.resolveWeak('@site/docs/extensions/credentials/zkpass.md')],
  'e042a770': [() => import(/* webpackChunkName: 'e042a770' */ '@site/docs/tutorial/walletconnect_verida.mdx'), '@site/docs/tutorial/walletconnect_verida.mdx', require.resolveWeak('@site/docs/tutorial/walletconnect_verida.mdx')],
  'e6a20fbb': [() => import(/* webpackChunkName: 'e6a20fbb' */ '@site/docs/infrastructure/storage-node.md'), '@site/docs/infrastructure/storage-node.md', require.resolveWeak('@site/docs/infrastructure/storage-node.md')],
  'e747ec83': [() => import(/* webpackChunkName: 'e747ec83' */ '@site/docs/glossary.md'), '@site/docs/glossary.md', require.resolveWeak('@site/docs/glossary.md')],
  'e772b38a': [() => import(/* webpackChunkName: 'e772b38a' */ '@site/docs/concepts/design-principles.md'), '@site/docs/concepts/design-principles.md', require.resolveWeak('@site/docs/concepts/design-principles.md')],
  'e8b97c8c': [() => import(/* webpackChunkName: 'e8b97c8c' */ '@site/docs/client-sdk/client-sdk.md'), '@site/docs/client-sdk/client-sdk.md', require.resolveWeak('@site/docs/client-sdk/client-sdk.md')],
  'fb8d87aa': [() => import(/* webpackChunkName: 'fb8d87aa' */ '@site/docs/extensions/verida-uris.md'), '@site/docs/extensions/verida-uris.md', require.resolveWeak('@site/docs/extensions/verida-uris.md')],
  'fcd4a76c': [() => import(/* webpackChunkName: 'fcd4a76c' */ '@site/docs/tutorial/SSO.mdx'), '@site/docs/tutorial/SSO.mdx', require.resolveWeak('@site/docs/tutorial/SSO.mdx')],};
